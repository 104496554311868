import Image from "next/image";
import Link from "next/link";
import { FaInstagram, FaSpotify, FaTiktok, FaYoutube } from "react-icons/fa6";

export default function Footer() {
    return (
        <footer className="footer bg-primary px-4 md:px-24 py-16 mt-16">
                <aside>
                    <Image width={144} height={500} src="/logo-white.svg" alt="cards logo" />
                    <p className="text-white text-opacity-60">Digitalisasi Sistem Operasional Pendidikan.</p>
                    <p className="text-white text-opacity-60 tracking-wider">&copy; 2021-{new Date().getFullYear()} CARDS Kartu Digital</p>
                </aside> 
                <nav>
                    <h6 className="footer-title text-white opacity-100 font-semibold normal-case">Links</h6> 
                    <Link href={'/'} className="link link-hover text-white text-opacity-60">Home</Link>
                    <Link href={'/tentang/kami'} className="link link-hover text-white text-opacity-60">Tentang Kami</Link>
                    <Link href={'/tentang/mitra-bisnis'} className="link link-hover text-white text-opacity-60">Mitra Bisnis</Link>
                    <Link href={'/tentang/kegiatan'} className="link link-hover text-white text-opacity-60">Kegiatan</Link>
                </nav> 
                <nav>
                    <h6 className="footer-title text-white opacity-100 font-semibold normal-case">Service</h6> 
                    <Link href={'/tos'} className="link link-hover text-white text-opacity-60">Terms Of Service</Link>
                    <Link href={'/privacy-policy'} className="link link-hover text-white text-opacity-60">Privacy Policy</Link>
                    <Link href={'/daftar'} className="link link-hover text-white text-opacity-60">Pendaftaran Partner</Link>
                    <Link href={'/kontak'} className="link link-hover text-white text-opacity-60">Kontak</Link>
                </nav> 
                <nav>
                    <h6 className="footer-title text-white opacity-100 font-semibold normal-case">Selalu Update</h6> 
                    <p className="!no-underline cursor-auto link link-hover text-white text-opacity-60">Temukan kami di sosial<br/>media berikut:</p>
                    <div className="flex gap-2">
                        <Link aria-label="Cards Instagram" href={'https://www.instagram.com/cazhcards/'} target="_blank" className="w-10 h-10 bg-tprimary-3 rounded text-white/80 hover:text-white flex justify-center items-center">
                            <FaInstagram className="w-5 h-5" />
                        </Link>
                        <Link aria-label="Cards Facebook" href={'https://facebook.com'} target="_blank" className="w-10 h-10 bg-tprimary-3 rounded text-white/80 hover:text-white flex justify-center items-center">
                            <FaTiktok className="w-5 h-5" />
                        </Link>
                        <Link aria-label="Cards Youtube" href={'https://www.youtube.com/@cazhcards'} target="_blank" className="w-10 h-10 bg-tprimary-3 rounded text-white/80 hover:text-white flex justify-center items-center">
                            <FaYoutube className="w-5 h-5" />
                        </Link>
                        <Link aria-label="Cards Spotify" href={'https://spotify.com'} target="_blank" className="w-10 h-10 bg-tprimary-3 rounded text-white/80 hover:text-white flex justify-center items-center">
                            <FaSpotify className="w-5 h-5" />
                        </Link>
                    </div>
                </nav> 
        </footer>
    )
}